import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer'
import { motion, AnimatePresence } from 'framer-motion';

import { SanityImage } from '@components/sanity/sanityImage';
import { ModuleWrapper } from '../moduleWrapper';

function separateIt(arr, size) {
  var newArr = [];
  for (var i = 0; i < arr.length; i += size) {
    var sliceIt = arr.slice(i, i + size);
    newArr.push(sliceIt);
  }
  return newArr;
}

const PartnerLogo = ({ _key, index, ...props }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      key={_key}
      className="px-6"
      transition={{ duration: 0.5 }}
    >
      <div className="aspect-w-3 aspect-h-2">
        <SanityImage image={props} className="absolute w-full h-full object-contain" />
      </div>
    </motion.div>
  );
};

const PartnerLogos = ({ config, title, logos }) => {
  const [index, setIndex] = useState(0);
  const splitLogos = separateIt(logos, 12);

  const { ref, inView } = useInView({
    threshold: 1,
    triggerOnce: true,
  });

  //setInterval when iniview is true only once
  useEffect(() => {
    if (inView) {
      setInterval(() => {
        return setIndex((prev) => (prev === splitLogos.length - 1 ? 0 : prev + 1));
      }, 6000);
    }
  }, [inView]);

  return (
    <ModuleWrapper {...config}>
      <div className="px-gutter md:py-10">
        <h2 className="blockH2 text-red text-center mb-three" ref={ref}>{title}</h2>
        <motion.div className="grid grid-cols-2 gap-10 md:grid-cols-4 md:gap-x-20">
          <AnimatePresence exitBeforeEnter>
            {splitLogos[index].map((item, index) => (
              <PartnerLogo {...item} index={index} />
            ))}
          </AnimatePresence>
        </motion.div>
      </div>
    </ModuleWrapper>
  );
};

export default PartnerLogos;
